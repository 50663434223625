<template>
    <div  class="global-content">
        <div class="block global-margin-bottom global-shadow">
            <div><i class="fa fa-arrow-right global-title-fa"></i><span class="global-title">请选择您要操作的设备</span><span class="global-remark">（单选）</span></div>
            <van-divider />
            <div class="handle"  v-if="devData.length != 0">
                <van-radio-group class="box-container" v-model="result" @change="changeValue" direction="horizontal">
                    <van-col span="8" v-for="(item,index) in devData" :key="index">
                        <van-radio checked-color="#0957ff" :name="item.devCode">{{item.devName}}</van-radio>
                    </van-col>
                </van-radio-group>
            </div>
            <div v-else class="empty-hint">{{hintText}}</div>
        </div>
        <div class="block global-shadow">
            <div><i class="fa fa-microphone global-title-fa"></i><span class="global-title">语音对讲</span></div>
            <van-divider />
            <div class="info">
                <audio controls>
                    <source src="@/assets/itgs/horse.ogg" type="audio/ogg">
                    您的浏览器不支持 audio 元素。
                </audio>
                <div><van-button color="#0957ff" class="start-video" @click="play" round>开始/结束</van-button></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'chat',
    data(){
        return{
            devData:[],
            result: '',
            hintText:'加载中...'
        }
    },
    mounted(){
         // 获取所有设备信息
        const projectCode = window.sessionStorage.getItem('projectCode');
        this.$api.ITGS.getAllDevInfo({
            projectCode:projectCode,
            controlRtcAudio:1
        }).then((data)=>{
            if(Array.isArray(data)){
                data.length == 0 ?  this.hintText='具备功能的设备列表为空' : this.devData = data;        
            }
        })
        if(this.$route.query.devCode){
            this.result = this.$route.query.devCode
        }
    },
    methods:{
        //单选绑定事件
        changeValue(e){
            // console.log(e)
        },
        play(){
            this.$toast('功能开发中,敬请期待')
        }
    }
}
</script>
<style lang="scss" scoped>
.block{
    background-color: #fff;
    padding: 2%;
    border-radius: 10px;
    .handle{
        padding: 0 4% 4% 4%;
        font-size: 0.95rem;
        .box-container{min-height:4rem;max-height:9.8rem;overflow-x: hidden;overflow-y: scroll;}
        .van-col{margin-bottom: 6%;}
    }
    .info{
        color:#656565;
        text-align: center;
        .active{color: #0957ff;}
        .line-block{text-align: left;padding-left: 5%;}
        .van-col{margin-bottom: 8%;}
        .fa{font-size: 1.5rem;vertical-align:middle}
        .text{font-size: .85rem;padding-top: 4px;}
        .start-video{margin: 4% 0;padding: 2px 22px;height: 36px;}
    }
}
</style>